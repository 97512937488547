
import { QRCode } from 'react-qrcode-logo';
import '../styles/App.css';
import { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Navbar from '../components/Navbar'

function App() {
  const [code, setCode] = useState();

  useEffect(() => {
    async function getBonus() {
      var response = await fetch('https://lootaedut.com/api/getbonus');
      var body = await response.json();
      console.log(body);

      setCode(body['code']);

      var hasClaimed = false;

      //get new code after 8s
      setInterval(async () => {
        if(hasClaimed !== false) return;


        var response = await fetch('https://lootaedut.com/api/refresh');
        var body = await response.json();
        if(!body['code']) return window.location.reload(); //if api returns null, refresh

        console.log(`Fetched status:  ${body['fetched']}`);

        if(body['fetched'] === true) {
          toast.success('Hesburger QR Lunastettu!', {duration: 5000, style: {background: '#333', color: '#fff'}});
          hasClaimed = true;
          return;
        };
        setCode(body['code']);
      }, 5000);
    };

    getBonus();
  }, []);

  return (
    <div>
      <Toaster/>
      <Navbar/>
      <div class="banner">
        <img class="title" src="/logo.png"></img>
        <div id="qrCodeBox">
          <div id="qrCodeSquareFrame">
            <div class="centered">
              <img width="350px" src={`https://quickchart.io/qr?text=${code}&ecLevel=L&margin=2&size=250&format=svg`}/>
            </div>
            <div class="text">
              <p id="one">
                Hesburger QR
              </p>
              <p id="two">
                Loota Productions 1937
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
